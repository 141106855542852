var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/_err-mntring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c9a2c85f76ccf8554c1915dae0dd288c2d2e2a3b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { SupabaseClient } from '@supabase/supabase-js'
import { SupabaseIntegration } from '@supabase/sentry-js-integration'

Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',

    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    integrations: [
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true
        }),
        Sentry.browserTracingIntegration({
            enableInp: true,
            shouldCreateSpanForRequest: (url) => {
                return !url.startsWith(`${process.env.NEXT_PUBLIC_API_URL}/rest`)
            }
        }),
        Sentry.browserProfilingIntegration(),
        new SupabaseIntegration(SupabaseClient, {
            tracing: true,
            breadcrumbs: true,
            errors: true
        })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0

    // ...

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
})
